import { graphql } from 'gatsby';
import { Location } from 'history';
import React, { useEffect } from 'react';
import { RESET_ADD_A_DRIVER_STATE } from 'state/actions';
import { useErrorState } from 'state/error/actions';
import useDispatch from 'state/useDispatch';
import ConfirmationBaseComponent from 'components/FormPage/confirmation';
import FormPageWrapper from 'components/Forms/FormPageWrapper';
import Layout from 'components/Layout';
import { CsCta } from 'types/contentStack';
import { ConfirmationState, DriverType } from 'types/forms';

export type ConfirmationPageProps = {
  data: ConfirmationPageContent;
  location: Location & {
    state?: {
      confirmationState: ConfirmationState;
    };
  };
};

const ConfirmationPage: React.FC<ConfirmationPageProps> = ({ location, data }) => {
  const [, updateErrorState] = useErrorState();
  const dispatch = useDispatch();

  const confirmationState = location.state?.confirmationState;

  useEffect(() => {
    dispatch({ type: RESET_ADD_A_DRIVER_STATE });
  }, [dispatch]);

  useEffect(() => {
    if (!confirmationState) {
      updateErrorState({ hasErrored: true });
    }
  }, [confirmationState, updateErrorState]);

  if (!confirmationState) {
    return (
      <FormPageWrapper
        location={location}
        title={data.csAddDriverConfirmationPage.title}
        formPageName="confirmation"
        driverType={DriverType.PERMANENT}>
        <Layout pageType="form" meta={{ title: data.csAddDriverConfirmationPage.title }} />
      </FormPageWrapper>
    );
  }

  return (
    <ConfirmationBaseComponent
      data={{
        ...data.csAddDriverConfirmationPage,
        summary: data.csAddDriverConfirmationPage.summary_permanent_driver,
      }}
      location={{ ...location, state: { confirmationState } }}
    />
  );
};

export default ConfirmationPage;

export const query = graphql`
  query {
    csAddDriverConfirmationPage {
      title
      heading
      successfully_added
      start_date
      end_date
      replaced_permanent_driver
      summary_permanent_driver
      return_cta {
        display_text
        screen_reader_text
        url
        open_in_new_tab
      }
    }
  }
`;

export type CsConfirmationPage = {
  title: string;
  heading: string;
  successfully_added: string;
  start_date: string;
  end_date: string;
  replaced_permanent_driver: string;
  summary_permanent_driver: string;
  return_cta: [CsCta];
};

export type ConfirmationPageContent = {
  csAddDriverConfirmationPage: CsConfirmationPage;
};
