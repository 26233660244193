import { SecondaryCta } from '@rsa-digital/evo-shared-components/components/Cta';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { formatLongDateWithDayFirst } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { formatLocalTimeInTwentyFourHourFormat } from '@rsa-digital/evo-shared-components/helpers/timeHelpers';
import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import FormHeading from 'components/Forms/FormHeading';
import FormPageWrapper from 'components/Forms/FormPageWrapper';
import Layout from 'components/Layout';
import { processMandatoryCta } from 'helpers/csTypeProcessors';
import { isTemporaryDriver } from 'helpers/driverTypeHelper';
import {
  CsPlaceholders,
  replacePlaceholdersPlainText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { CsCta } from 'types/contentStack';
import { ConfirmationState, DriverType } from 'types/forms';
import { CtaContainer, Label, Text } from './styles';

export type ConfirmationBaseComponentProps = {
  data: ConfirmationPageContent;
  location: Location & {
    state: {
      confirmationState: ConfirmationState;
    };
  };
};

const driverTypePlaceholder: CsPlaceholders<DriverType> = {
  driverType: {
    getSubstitutionText: (driverType: DriverType): string => driverType.toLowerCase() ?? '',
    isPii: false,
  },
};

const driverNamePlaceholder: CsPlaceholders<string> = {
  driverName: {
    getSubstitutionText: (driverName: string): string => driverName,
    isPii: true,
  },
};

const formatDateTime = (dateTime: Date | string): string => {
  const formattedDate = formatLongDateWithDayFirst(dateTime);
  const formattedTime = formatLocalTimeInTwentyFourHourFormat(dateTime);
  return `${formattedDate}, ${formattedTime}`;
};

const ConfirmationBaseComponent: React.FC<ConfirmationBaseComponentProps> = ({
  location,
  data,
}) => {
  const {
    driverType,
    coverStartDate,
    coverEndDate,
    replacedDriverName,
  } = location.state.confirmationState;

  const {
    title,
    heading,
    summary,
    start_date: startDateLabel,
    successfully_added: successfullyAddedText,
    end_date: endDateLabel,
    replaced_permanent_driver: replacedPermanentDriverText,
    return_cta: returnCta,
  } = data;

  const replaceDriverType = replacePlaceholdersPlainText(driverTypePlaceholder, driverType);
  const replaceDriverName = replacePlaceholdersPlainText(
    driverNamePlaceholder,
    replacedDriverName ?? '',
    true // Text using this is wrapped in an element with `data-pii-mask="true"`
  );

  return (
    <FormPageWrapper
      location={location}
      title={title}
      formPageName="confirmation"
      driverType={driverType}>
      <Layout pageType="form" meta={{ title }}>
        <Grid alignLeft>
          <GridItem desktop={8} tabletLandscape={8}>
            <FormHeading text={heading} />
            <Text as="p">{replaceDriverType(successfullyAddedText)}</Text>
            <Label data-cy="coverStartDateText">{startDateLabel}</Label>
            <Text data-cy="coverStartDate">{formatDateTime(coverStartDate)}</Text>
            {coverEndDate && (
              <>
                <Label data-cy="coverEndDateText">{endDateLabel}</Label>
                <Text data-cy="coverEndDate">{formatDateTime(coverEndDate)}</Text>
              </>
            )}
            <RichText html={summary} />
            {isTemporaryDriver(driverType) && replacedDriverName && (
              <Text as="p" data-pii-mask="true">
                {replaceDriverName(replacedPermanentDriverText)}
              </Text>
            )}
          </GridItem>
        </Grid>
        <CtaContainer>
          <SecondaryCta data-cy="returnToAccount" cta={processMandatoryCta(returnCta, 'Body')} />
        </CtaContainer>
      </Layout>
    </FormPageWrapper>
  );
};

export default ConfirmationBaseComponent;

export const query = graphql`
  query {
    csAddDriverConfirmationPage {
      title
      heading
      successfully_added
      start_date
      end_date
      replaced_permanent_driver
      summary_permanent_driver
      summary_temporary_driver
      summary_temporary_replacement_driver
      return_cta {
        display_text
        screen_reader_text
        url
        open_in_new_tab
      }
    }
  }
`;

export type ConfirmationPageContent = {
  title: string;
  heading: string;
  successfully_added: string;
  start_date: string;
  end_date: string;
  replaced_permanent_driver: string;
  summary: string;
  return_cta: [CsCta];
};
